import React, { useState, useEffect } from "react"
import loadable from '@loadable/component'

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/bookviewingselect'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import { getIpAddress } from "../common/site/functions"
import { getAllCookie } from "../common/site/cookie";
import axios from "axios"
import * as qs from "query-string"
import $ from "jquery"
const Address = loadable(() => import('./elements/register-address'))

function BookAView(props) {
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [ipAddress, setIpAddress] = useState("");
  const [errormsg, setErrorMsg] = useState("Highlighted fields are required");
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
    {
      element: "config",
      formname: "Arrange a Viewing",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for your interest. We will be in touch with you shortly. ",
      email_temp_user: "book_a_viewing_user",
      email_temp_admin: "book_a_viewing_admin",
      email_subject_user: `Your Viewing Request`,
      email_subject_admin: "Arrange a Viewing",
      email_server_func: "arrange_a_viewing",
      event_tracking: "arrange_a_viewing",
      page_url: "/general-enquiry"
    },
    {
      grpmd: "12",
      label: "Name*",
      placeholder: "Your name",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Email*",
      placeholder: "Your email address",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",

    },
    {
      grpmd: "12",
      label: "Telephone*",
      placeholder: "Your telephone number",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",

    },
    {
      grpmd: "12",
      label: "Preferred date:",
      placeholder: "Select preferred date",
      name: "preferred_date",
      type: "date_time",
      element: "input",
      // min: today,
      // max: maxday.toString(),
      required: false,
      patternchk: "",
      class: "mb-4 form-control"
    },
    {
      grpmd: "12",
      label: "Preferred time:",
      placeholder: "Select preferred time",
      name: "preferred_time",
      type: "timePicker",
      element: "input",
      // min: today,
      // max: maxday.toString(),
      required: false,
      patternchk: "",
      class: "mb-4 form-control"
    },
    props.subject.type == 'sales' &&
    {
      grpmd: "12",
      label: "Your situation",
      placeholder: "Please select any one",
      name: "situation",
      element: "select",
      required: false,
      type: "select",
      values: ['First time buyer', 'Property to sell, not yet on the market', 'Property on the market', 'Under offer', 'Nothing to sell', 'Investor'],
      labelClass: "content_b-18"
    },
    {
      element: "loqate"
    },
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Write your message here",
      name: "message",
      element: "textarea",
      class: "mb-4",
      rows: "2"
    },
    {
      grpmd: "12",
      label: "Over 60",
      placeholder: "Over 60? Find out how you could boost your budget by up to 59%",
      name: "oversixty",
      element: "checkbox",
      class: "mb-4 over-60-checkbox",
      required: false,
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Marketing consent",
      placeholder: "I'm happy to hear more about marketing and other services by email",
      name: "marketing_consent_email",
      element: "checkbox",
      class: "over-60-checkbox mc_checkbox col-md-12 mb-2",
      required: false,
      labelClass: "content_b-18"
    },
    {
      step: "col-md-12",
      name: "terms",
      placeholder: 'I agree to the <a href="/terms-and-conditions" class="form-text-link" target="_blank">Terms & Conditions</a> and <a href="/privacy-policy" class="form-text-link" target="_blank"> Privacy Policy </a>. <br>',
      class: "mb-4 over-60-checkbox col-md-12 terms-check",
      labelClass: "content_b-18",
      element: "checkbox",
      required: true
    },
    {
      name: "Arrange a Viewing",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 text-center",
      class: "btn-secondary",
      formclass: ""
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
    {
      name: "negotiatoremail",
      type: "hidden",
      element: "input",
      defaultValue: props.subject?.negotiator?.Email
    }
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    getIpAddress((ip) => {
      setIpAddress(ip)
    })

    if (token !== '') {

      const processFromData = async () => {
        setShowerror(false);

        formvalues['g-recaptcha-response'] = token;
        const page_url = typeof window !== 'undefined' ? window.location.href : ''

        var gtm_client_id = '';
        var user_agent = '';
        if (typeof window !== 'undefined') {
          user_agent = window.navigator.userAgent
          if (typeof window.ga !== 'undefined') {
            gtm_client_id = window.ga.getAll()[0].get('clientId');

          }
        }

        let formData = new FormData();
        formvalues['name'] = formvalues.name;
        formvalues['terms'] = formvalues.terms == "yes" ? true : false;
        formvalues['over-60s'] = formvalues.oversixty == "yes" ? true : false;
        formvalues['marketing_consent_email'] = formvalues.marketing_consent_email == "yes" ? true : false;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;
        // formvalues['address'] = {
        //   "stno": props.subject.address ? props.subject.house_name_number : '',
        //   "street": props.subject.address ? props.subject.street : '',
        //   "district": props.subject.address ? props.subject.district : '',
        //   "post_town": props.subject.address ? props.subject.town : '',
        //   "county": props.subject.address ? props.subject.county : '',
        //   "postcode": props.subject.address ? props.subject.postcode : ''
        // }

        formvalues['referrer'] = page_url;
        formvalues['google_analytics_client_id'] = gtm_client_id;
        formvalues['ip_address'] = ipAddress;
        formvalues['user_agent'] = user_agent;
        delete formvalues["address"]
        delete formvalues["oversixty"]
        formvalues['extra'] = JSON.stringify(formvalues);
        // formvalues['ip_address'] = public_ip;  

        formvalues['address'] = {
          "stno": formvalues.home_stno,
          "street": formvalues.home_street,
          "district": formvalues.home_district,
          "post_town": formvalues.home_post_town,
          "county": formvalues.home_county,
          "postcode": formvalues.home_postcode
        }
        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          // lets send mail

          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/functions`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        });

        const url = typeof window !== 'undefined' ? window.location.href : ''
        if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
          fields[0].formname = 'Get mortgage help';
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      if(document.querySelector('#terms').checked) {
        $(".terms-check .check-mark").removeClass("error");
      }
      else {
        $(".terms-check .check-mark").addClass("error");
      }
      event.preventDefault();
      event.stopPropagation();
      setErrorMsg("Highlighted fields are required");
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      if (document.getElementById('home_postcode').value != "") {
        event.preventDefault();
        setShowerror(false);
        const formsdata = (event.target);
        const json = {}
        Object.keys(formsdata).map(key => (
          json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
        ))
  
        json['email_temp_user'] = fields[0].email_temp_user;
        json['email_temp_admin'] = fields[0].email_temp_admin;
        json['formname'] = fields[0].formname;
        json['property_id'] = props.subject.property_id ? props.subject.property_id : '';
        json['property_url'] = props.subject.page_url ? props.subject.page_url : '';
        json['crm_id'] = props.subject.crm_id ? props.subject.crm_id : '';
        json['price'] = props.subject.price ? props.subject.price : '';
        json['type'] = props.subject.type ? props.subject.type : '';
        json['property_title'] = props.subject.property_title ? props.subject.property_title : '';
        json['property_img'] = props.crm_id.property_img ? props.subject.property_img : '';
        // json['property_list'] = props.subject.properties ? props.subject.properties : '';
        json['g-recaptcha-response'] = token;
  
        setFormvalues(json);
  
        recaptchaRef.current.execute();
        // setToken("aaa")
  
        setValidated(false);
  
        // reset form
        const form = event.target
        form.reset();
        setStartDate()
        setStartTime()
      }
      else {
        event.preventDefault();
        event.stopPropagation();
        setErrorMsg("Please select a full address from the list");
        document.getElementById('address').value = ''
        setShowerror(true);
        setValidated(true);
        setThankyou(false);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }

    }
  };

  const url = typeof window !== 'undefined' ? window.location.href : ''

  var cookieData = getAllCookie();
  return (
    <div className="form stbform valuation-main book-a-viewing-form">
      <div ref={myRef} />
      <div className="form stbform">


        <Form className="form contact-landing-form customer-complaint-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>

          {showerror && <div className="alert alert-danger alert-error">
            <p>{errormsg}</p>
          </div>}

          {showthankyou && <div className="alert alert-success">
            <p>{fields[0].success_text}</p>
          </div>}

          <input type="hidden" name="form_name" value={fields[0].formname} />
          <input type="hidden" name="form_type" value={fields[0].form_type} />
          <input type="hidden" name="to_email_id" value={props.office_email ? props.office_email + "," + props.office_usearea_email : ''} />
          <input type="hidden" name="to_email_id" value={props.to_email_id ? props.to_email_id : ''} />
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form_type_id" value="1" />
          <input type="hidden" name="form_page" value={url} />
          <input type="hidden" name="utm_source" value={cookieData.utm_source} />
          <input type="hidden" name="utm_campaign" value={cookieData.utm_campaign} />
          <input type="hidden" name="utm_term" value={cookieData.utm_term} />
          <input type="hidden" name="utm_content" value={cookieData.utm_content} />
          <input type="hidden" name="utm_medium" value={cookieData.utm_medium} />
          <input type="hidden" name="brand_id" value={process.env.GATSBY_STRAPI_BRAND_ID} />
          <input type="hidden" name="property_ref" value={props.crm_id} />
          <div className="step-block">
            {fields.map((field, index) => {
              if ("input" === field.element) {
                return (
                  <InputField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    type={field.type}
                    startDate={field.type === 'date_time' ? startDate : ''}
                    setStartDate={field.type === 'date_time' ? setStartDate : ''}
                    startTime={field.type === 'timePicker' ? startTime : ''}
                    setStartTime={field.type === 'timePicker' ? setStartTime : ''}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    label={field.label}
                    labelClass={field.labelClass}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    pattern={field.patternchk}
                    handlechange={handlechange}
                    defaultValue={field.defaultValue}
                  />
                );
              }
              if ("select" === field.element) {
                return (
                  <SelectField
                    name={field.name}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    required={field.required}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    values={field.values}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    componentprops={props}
                  />
                );
              }
              if ("textarea" === field.element) {
                return (
                  <TextAreaField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    label={field.label}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                  />
                );
              }
              if ("checkbox" === field.element) {
                return (
                  <CheckboxField
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={handlechange}
                  />
                );
              }
              if ("radio" === field.element) {
                return (
                  <RadioField
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    checked={field.checked}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={handlechange}
                    lastchild={field.lastchild}
                  />
                );
              }
              if ("loqate" === field.element) {
                return (
                  <Address />
                )
              }
              if ("html" === field.element) {
                return (
                  <HtmlBox
                    text={field.text}
                    fieldClass={field.class}
                    key={`${field.element}~${index}`}
                  />
                );
              }
              if ("captcha" === field.element) {
                return (
                  <ReCaptchaBox
                    fieldClass={field.class}
                    captRef={field.captchaRef}
                    key={`${field.element}~${index}`}
                    handleonVerify={handleonVerify}
                  />
                );
              }
              if ("button" === field.element) {
                return (
                  <Form.Group>
                    <ButtonField
                      name={field.name}
                      fieldClass={field.class}
                      step={field.step}
                      formclass={field.formclass}
                      type={field.type}
                      value={field.value}
                      key={`${field.element}~${index}`}
                    />
                  </Form.Group>
                );
              }
            })
            }
          </div>
        </Form>
      </div>
    </div>
  );
}


const BookAViewForm = (props) => (
  <BookAView subject={props} crm_id={props.crm_id} to_email_id={props.to_email_id} office_email={props.office_email} office_usearea_email={props.office_usearea_email} />
)

export default BookAViewForm
